<template>
  <section class="space-y-10">
    <QuickInfo />
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article v-if="canUseCoinTube">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-3"
      >
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="$router.push('cointube/use')"
          icon="swap"
          text="Use Coin Tubes"
        >
        </asom-button>
      </section>
    </article>
    <asom-card class="overflow-y-auto">
      <asom-grid :sm="1" :md="4" class="overflow-y-auto">
        <asom-form-field :label="'Location'" class="">
          <p class="text-sm text-gray-500">AFC</p>
        </asom-form-field>
        <asom-form-field :label="'Total Cash Balance'" class="">
          <p class="text-sm text-gray-500">${{ summary.cashBalance }}</p>
        </asom-form-field>
        <denominations
          :label="'Current Coin Tube Balance'"
          :readonly="true"
          class="col-span-2"
          :noOf10CentTubes="summary.noOf10CentTubes"
          :noOf20CentTubes="summary.noOf20CentTubes"
          :noOf50CentTubes="summary.noOf50CentTubes"
          :noOf1DollarTubes="summary.noOf1DollarTubes"
        />
      </asom-grid>
    </asom-card>
    <article>
      <h3 class="subheader">
        CoinFloat e-Log Summary (Number of Coin Tubes)
      </h3>
      <br />
      <div class="shadow overflow-auto border-b border-gray-200 sm:rounded-lg mb-3 pb-2">
        <table class="min-w-full divide-y divide-gray-200">
          <caption class="hidden"></caption>
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" colspan="3"></th>
              <th
                scope="col"
                colspan="3"
                class=" border-l border-r border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                10¢
              </th>
              <th
                scope="col"
                colspan="3"
                class=" border-l border-r border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                20¢
              </th>
              <th
                scope="col"
                colspan="3"
                class=" border-l border-r border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                50¢
              </th>
              <th
                scope="col"
                colspan="3"
                class=" border-l border-r border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                $1
              </th>
            </tr>
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Transaction ID
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Date
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Doc Ref
              </th>
              <!-- 10¢ -->
              <th
                scope="col"
                class=" border-l border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                In
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Out
              </th>
              <th
                scope="col"
                class=" border-r border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Bal
              </th>
              <!-- /\ 10¢ -->

              <!-- 20¢ -->
              <th
                scope="col"
                class=" border-l border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                In
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Out
              </th>
              <th
                scope="col"
                class=" border-r border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Bal
              </th>
              <!-- /\ 20¢ -->

              <!-- 50¢ -->
              <th
                scope="col"
                class=" border-l border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                In
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Out
              </th>
              <th
                scope="col"
                class=" border-r border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Bal
              </th>
              <!-- /\ 50¢ -->

              <!-- $1 -->
              <th
                scope="col"
                class=" border-l border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                In
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Out
              </th>
              <th
                scope="col"
                class=" border-r border-gray-500 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Bal
              </th>
              <!-- /\ $1 -->

              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                LOC
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Remarks
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Staff
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200 text-sm">
            <template
              v-for="transaction in shownData"
              :key="transaction.coinTubeTransactionId"
            >
              <tr>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ transaction.transactionNo }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ displayUtcDate(transaction.dateCreated) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ transaction.documentReference }}
                </td>
                <!-- 10¢ -->
                <td
                  class=" border-l border-gray-500 px-6 py-4 whitespace-nowrap"
                >
                  {{ displayCoinTubeVal(transaction.noOf10CentTubes, "IN") }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ displayCoinTubeVal(transaction.noOf10CentTubes, "OUT") }}
                </td>
                <td
                  class=" border-r border-gray-500 px-6 py-4 whitespace-nowrap"
                >
                  {{ transaction.balanceOf10CentTubes }}
                </td>
                <!-- /\ 10¢ -->

                <!-- 20¢ -->
                <td
                  class=" border-l border-gray-500 px-6 py-4 whitespace-nowrap"
                >
                  {{ displayCoinTubeVal(transaction.noOf20CentTubes, "IN") }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ displayCoinTubeVal(transaction.noOf20CentTubes, "OUT") }}
                </td>
                <td
                  class=" border-r border-gray-500 px-6 py-4 whitespace-nowrap"
                >
                  {{ transaction.balanceOf20CentTubes }}
                </td>
                <!-- /\ 20¢ -->

                <!-- 50¢ -->
                <td
                  class=" border-l border-gray-500 px-6 py-4 whitespace-nowrap"
                >
                  {{ displayCoinTubeVal(transaction.noOf50CentTubes, "IN") }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ displayCoinTubeVal(transaction.noOf50CentTubes, "OUT") }}
                </td>
                <td
                  class=" border-r border-gray-500 px-6 py-4 whitespace-nowrap"
                >
                  {{ transaction.balanceOf50CentTubes }}
                </td>
                <!-- /\ 50¢ -->

                <!-- $1 -->
                <td
                  class=" border-l border-gray-500 px-6 py-4 whitespace-nowrap"
                >
                  {{ displayCoinTubeVal(transaction.noOf1DollarTubes, "IN") }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ displayCoinTubeVal(transaction.noOf1DollarTubes, "OUT") }}
                </td>
                <td
                  class=" border-r border-gray-500 px-6 py-4 whitespace-nowrap"
                >
                  {{ transaction.balanceOf1DollarTubes }}
                </td>
                <!-- /\ $1 -->

                <td class="px-6 py-4 whitespace-nowrap">
                  {{ transaction.location }}
                </td>
                <td class="px-6 py-4 max-w-lg break-words whitespace-pre-line">
                  {{ transaction.remarks }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ transaction.createdBy }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="flex ml-auto w-100">
        <p class="AsomTable__Label" >
          Showing
          <span class="AsomTable__Label--highlighted">{{
            firstShownItemIndex() + 1
          }}</span>
          to
          <span class="AsomTable__Label--highlighted">{{
            lastShownItemIndex() + 1
          }}</span>
          of
          <span class="AsomTable__Label--highlighted">{{
            totalFilteredItems()
          }}</span>
          results
        </p>
        <asom-pagination class="ml-auto"
        v-if="summary.coinTubeTransactions.length > itemsPerPage"
        :total-items="totalFilteredItems()"
        :items-per-page="itemsPerPage"
        :modelValue="currentPageNo"
        @update:modelValue="setCurrentPage"
        />
      </div>
    </article>
    <article>
      <h3 class="subheader">
        Activities
      </h3>
      <br />
      <activity-log-list :data="summary.activityLogs" />
    </article>
  </section>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { getCoinFloatSummary } from "@/services/cashManagement.service";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import QuickInfo from "../../_QuickInfo.vue";
import ActivityLogList from "../../_ActivityLogList.vue";
import Denominations from "../../_Denominations.vue";
import AsomPagination from "../../../../components/pagination/AsomPagination.vue"

export default {
  components: {
    QuickInfo,
    ActivityLogList,
    Denominations,
    AsomPagination,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      summary: {
        cashBalance: 0,
        noOf10CentTubes: 0,
        noOf20CentTubes: 0,
        noOf50CentTubes: 0,
        noOf1DollarTubes: 0,
        coinTubeTransactions: [],
        activityLogs: [],
      },
      shownData: [],
      currentPageNo: 1,
      itemsPerPage: 10,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      canUseCoinTube: 'auth/canUseCoinTube'
    }),
  },
  watch: {
    currentPageNo(){
      console.log(this.firstShownItemIndex(), this.lastShownItemIndex() + 1);
      this.setShownData();
    }
  },
  methods: {
    setShownData(){
      this.shownData = this.summary.coinTubeTransactions
      .slice(
        this.firstShownItemIndex(),
        this.lastShownItemIndex() + 1
      );
    },
    displayUtcDate,
    displayCoinTubeVal(value, column) {
      let result = "";
      switch (column) {
        case "IN":
          result = value > 0 ? value : "nil";
          break;
        case "OUT":
          result = value < 0 ? value : "nil";
          break;
        case "BAL":
          result = value !== 0 ? value : "nil";
          break;
        default:
          break;
      }
      return result;
    },
    async loadData() {
      this.error = null;
      this.isLoading = true;
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const result = await getCoinFloatSummary(this.afcId);
      if (result.success) {
        this.setData(result);
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
      this.setShownData();
      this.isLoading = false;
    },
    setData(apiResult) {
      this.summary.cashBalance = get(apiResult, "payload.data.cashBalance", 0);
      this.summary.noOf10CentTubes = get(
        apiResult,
        "payload.data.noOf10CentTubes",
        0
      );
      this.summary.noOf20CentTubes = get(
        apiResult,
        "payload.data.noOf20CentTubes",
        0
      );
      this.summary.noOf50CentTubes = get(
        apiResult,
        "payload.data.noOf50CentTubes",
        0
      );
      this.summary.noOf1DollarTubes = get(
        apiResult,
        "payload.data.noOf1DollarTubes",
        0
      );
      this.summary.coinTubeTransactions = get(
        apiResult,
        "payload.data.coinTubeTransactions",
        []
      );
      this.summary.activityLogs = get(
        apiResult,
        "payload.data.activityLogs",
        []
      );
    },
    setCurrentPage(pageNo) {
      this.currentPageNo = pageNo;
    },
    firstShownItemIndex() {
      return this.totalFilteredItems() == 0 ? -1 : (this.currentPageNo - 1) * this.itemsPerPage;
    },
    lastShownItemIndex() {
      return this.currentPageNo * this.itemsPerPage < this.totalFilteredItems()
          ? this.currentPageNo * this.itemsPerPage - 1
          : this.totalFilteredItems() - 1;
    },
    totalFilteredItems(){
      return this.summary.coinTubeTransactions.length;
    }
  },
};
</script>
<style>
</style>